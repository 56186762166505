import { CarEntry } from '@/models/car.model';
import { OrganisationEntry } from '@/models/organisation.model';

const organsiationOrder: { [key: string]: number } = {
  'Löschzug Everswinkel': 0,
  'Lz.Alverskirchen/Everswinkel': 1,
  'Löschgruppe Everswinkel': 2,
  Löschgruppe: 3,
  'Löschzug Alverskirchen': 4,
  'Löschgruppe Alverskirchen': 5,
  Polizei: 6,
  Rettungsdienst: 7,
  'First Responder': 7,
  RTW: 8,
  NA: 9,
  DRK: 10,
  'O-Amt': 11,
  'Straßen-Gefahrstoffreinigung Fa. Hülsmann': 12,
  'Untere Wasserbehörde': 13,
  Schornsteinfeger: 14,
  'Feuerwehr Telgte': 15,
  'Löschzug Telgte': 15,
  'Feuerwehr Sendenhorst': 16,
  'Löschzug Sendenhorst': 16,
  'Löschzug Hoetmar': 17,
  'Löschzug Freckenhorst': 18,
  'Feuerwehr Warendorf': 19,
  KBM: 20,
  'Stellv.KBM': 21,
  Fernmeldeeinheit: 22,
  'IUK Fernmeldeeinheit': 22,
  GSG2: 23,
  'Strahlenschutz-Messzug': 24,
  THW: 25,
  Bestattungsunternehmen: 26
};

export const sortOrganisations = (organisationEntries: OrganisationEntry[]) => {
  organisationEntries.sort((lhs, rhs) => {
    const lhsScore = organsiationOrder[lhs.name];
    const rhsScore = organsiationOrder[rhs.name];
    if (lhs == null && rhs != null) {
      return 1;
    }

    if (lhs != null && rhs == null) {
      return -1;
    }

    return lhsScore - rhsScore;
  });

  return organisationEntries;
};

export const sortCars = (carEntries: CarEntry[]) => {
  carEntries.sort((lhs, rhs) => {
    const lname = lhs.name;
    const rname = rhs.name;

    if (rname === lname) {
      return 0;
    }

    const lcLname = lname.toLowerCase();
    const lcRname = rname.toLowerCase();

    if (lcLname.startsWith('eve') && !lcRname.startsWith('eve')) {
      return -1;
    }

    if (lcRname.startsWith('eve') && !lcLname.startsWith('eve')) {
      return 1;
    }

    return lname.replace(/\s/g, '') < rname.replace(/\s/g, '') ? -1 : 1;
  });

  return carEntries;
};
