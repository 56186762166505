// mutations
export const SET_REPORTS = 'SET_REPORTS';
export const ADD_OR_UPDATE_REPORT = 'ADD_OR_UPDATE_REPORT';
export const REMOVE_REPORTS = 'REMOVE_REPORTS';

// actions
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const CREATE_OR_SAVE_REPORT = 'CREATE_OR_SAVE_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';

// getters
export const GET_REPORTS = 'GET_REPORTS';
