import { AxiosResponse } from 'axios';

import axiosClient from '@/plugins/axios.client';

import { LocationEntry } from '@/models/location.model';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import $emitter from '@/plugins/mitt.event';

class LocationService {
  getAllLocations() {
    return axiosClient
      .get(`collections/get/einsatzort`)
      .then((resp: AxiosResponse) => resp.data.entries as LocationEntry[]);
  }

  saveLocation(location: LocationEntry | LocationEntry[]) {
    return axiosClient
      .post(`collections/save/einsatzort`, {
        data: location
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Ort erfolgreich angelegt.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Ort konnte nicht angelegt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Ort konnte nicht angelegt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }
}

export default new LocationService();
