import { AxiosResponse } from 'axios';

import axiosClient from '@/plugins/axios.client';

import { OrganisationEntry } from '@/models/organisation.model';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import $emitter from '@/plugins/mitt.event';

class OrganisationService {
  getAllOrganisations() {
    return axiosClient
      .get(`collections/get/einheit`)
      .then((resp: AxiosResponse) => resp.data.entries as OrganisationEntry[]);
  }

  saveOrgansiation(organisation: OrganisationEntry | OrganisationEntry[]) {
    return axiosClient
      .post(`collections/save/einheit`, {
        data: organisation
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Einheit erfolgreich angelegt.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Einheit konnte nicht angelegt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einheit konnte nicht angelegt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }

  removeOrganisations(ids: string[]) {
    return axiosClient
      .post(`collections/remove/einheit`, {
        filter: {
          _id: {
            $in: ids
          }
        }
      })
      .then((resp: AxiosResponse) => resp.data);
  }
}

export default new OrganisationService();
