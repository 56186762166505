export enum MessageEvent {
  Global = 'global-msg'
}

export enum MessageType {
  Success = 'success',
  Error = 'error',
  Default = 'primary'
}

export interface Message {
  type: MessageType;
  text: string;
}
