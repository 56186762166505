import { AxiosResponse } from 'axios';

import axiosClient from '@/plugins/axios.client';

import { OperationTypeEntry } from '@/models/operation-type.model';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import $emitter from '@/plugins/mitt.event';

class OperationTypeService {
  getAllOperationTypes() {
    return axiosClient
      .get(`collections/get/einsatzart`)
      .then((resp: AxiosResponse) => resp.data.entries as OperationTypeEntry[]);
  }

  saveOperationType(operationType: OperationTypeEntry | OperationTypeEntry[]) {
    return axiosClient
      .post(`collections/save/einsatzart`, {
        data: operationType
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Einsatzart erfolgreich angelegt.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Einsatzart konnte nicht angelegt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einsatzart konnte nicht angelegt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }
}

export default new OperationTypeService();
