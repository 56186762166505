// mutations
export const SET_ORGANISATIONS = 'SET_ORGANISATIONS';
export const ADD_OR_UPDATE_ORGANISATION = 'ADD_OR_UPDATE_ORGANISATION';
export const REMOVE_ORGANISATIONS = 'REMOVE_ORGANISATIONS';

// actions
export const FETCH_ORGANISATIONS = 'FETCH_ORGANISATIONS';
export const CREATE_OR_SAVE_ORGANISATION = 'CREATE_OR_SAVE_ORGANISATION';

// getters
export const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
