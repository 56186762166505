import { AxiosResponse } from 'axios';

import axiosClient from '@/plugins/axios.client';

import { CarEntry } from '@/models/car.model';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import $emitter from '@/plugins/mitt.event';

class CarService {
  getAllCars() {
    return axiosClient
      .get(`collections/get/fahrzeug`)
      .then((resp: AxiosResponse) => resp.data.entries as CarEntry[]);
  }

  saveCar(car: CarEntry | CarEntry[]) {
    return axiosClient
      .post(`collections/save/fahrzeug`, {
        data: car
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Fahrzeug erfolgreich angelegt.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Fahrzeug konnte nicht angelegt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Fahrzeug konnte nicht angelegt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }

  removeCars(ids: string[]) {
    return axiosClient
      .post(`collections/remove/fahrzeug`, {
        filter: {
          _id: {
            $in: ids
          }
        }
      })
      .then((resp: AxiosResponse) => resp.data);
  }
}

export default new CarService();
