// mutations
export const SET_OPERATIONS = 'SET_OPERATIONS';
export const ADD_OR_UPDATE_OPERATION = 'ADD_OR_UPDATE_OPERATION';
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_ASSET = 'ADD_ASSET';
export const SET_UPLOAD_IMAGES = 'SET_UPLOAD_IMAGES';
export const REMOVE_OPERATIONS = 'REMOVE_OPERATIONS';
export const REMOVE_IMAGES = 'REMOVE_IMAGES';

// actions
export const FETCH_OPERATIONS = 'FETCH_OPERATIONS';
export const CREATE_OR_SAVE_OPERATION = 'CREATE_OR_SAVE_OPERATION';
export const FETCH_IMAGE = 'FETCH_IMAGE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_OPERATION = 'DELETE_OPERATION';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const FETCH_ASSET = 'FETCH_ASSET';

// getters
export const GET_OPERATIONS = 'GET_OPERATIONS';
export const GET_IMAGE = 'GET_IMAGE';
export const GET_UPLOAD_IMAGES = 'GET_UPLOAD_IMAGES';
export const GET_ASSET = 'GET_ASSET';
