import axios, { AxiosRequestConfig } from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 5000
});

const getAuthToken = () => localStorage.getItem('token');
const getUnauthToken = () => '0830e7448efeb57351a70e03249d45';

const authInterceptor = (config: AxiosRequestConfig) => {
  config.headers['Cockpit-Token'] = getAuthToken() ?? getUnauthToken();
  return config;
};

axiosClient.interceptors.request.use(authInterceptor);

// axiosClient.interceptors.request.use(
//   (config: AxiosRequestConfig) => {
//     console.log(config);
//     return config;
//   },
//   (error: any) => {
//     return Promise.reject(error);
//   }
// );

export default axiosClient;
