






import { defineComponent, onMounted, provide } from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';

import $emitter from '@/plugins/mitt.event';

import { FETCH_OPERATIONS, GET_OPERATIONS } from '@/store/operation.actions';
import { FETCH_CARS } from '@/store/car.actions';
import { FETCH_ORGANISATIONS } from '@/store/organisation.actions';
import { FETCH_LOCATIONS } from '@/store/location.actions';
import { FETCH_OPERATION_TYPES } from '@/store/operation-type.actions';
import { FETCH_REPORTS, GET_REPORTS } from './store/report.actions';

export default defineComponent({
  name: 'App',
  setup: (props, { root }) => {
    provide('$emitter', $emitter);
    provide('$router', root.$router);

    const actions = useActions([
      FETCH_OPERATIONS,
      FETCH_ORGANISATIONS,
      FETCH_CARS,
      FETCH_LOCATIONS,
      FETCH_OPERATION_TYPES,
      FETCH_REPORTS
    ]);

    const fetchOperationsAction = actions[FETCH_OPERATIONS];
    const fetchCarsAction = actions[FETCH_CARS];
    const fetchOrganisationsAction = actions[FETCH_ORGANISATIONS];
    const fetchLocationsAction = actions[FETCH_LOCATIONS];
    const fetchOperationTypesAction = actions[FETCH_OPERATION_TYPES];
    const fetchReports = actions[FETCH_REPORTS];

    const getters = useGetters([GET_OPERATIONS, GET_REPORTS]);
    const operations = getters[GET_OPERATIONS];
    const reports = getters[GET_REPORTS];

    onMounted(async () => {
      if (!operations.value?.length) {
        await fetchOperationsAction();
      }

      if (!reports.value?.length) {
        await fetchReports();
      }

      await fetchCarsAction();
      await fetchOrganisationsAction();
      await fetchLocationsAction();
      await fetchOperationTypesAction();
    });

    return {};
  }
});
