import { Commit } from 'vuex';

import { OrganisationEntry } from '@/models/organisation.model';
import OrganisationService from '@/services/organisation.service';

import {
  SET_ORGANISATIONS,
  GET_ORGANISATIONS,
  FETCH_ORGANISATIONS,
  CREATE_OR_SAVE_ORGANISATION,
  ADD_OR_UPDATE_ORGANISATION,
  REMOVE_ORGANISATIONS
} from './organisation.actions';
import { wait } from '@/helpers/wait';
import { sortOrganisations } from '@/helpers/sort-entries';

interface State {
  organisations: OrganisationEntry[];
}

const state: State = {
  organisations: []
};

const getters = {
  [GET_ORGANISATIONS](state: State) {
    return state.organisations;
  }
};

const actions = {
  [FETCH_ORGANISATIONS]: async ({ commit }: { commit: Commit }) => {
    const organisationEntries = await OrganisationService.getAllOrganisations();
    sortOrganisations(organisationEntries);
    commit(SET_ORGANISATIONS, organisationEntries);
    return organisationEntries;
  },
  [CREATE_OR_SAVE_ORGANISATION]: async (
    { commit }: { commit: Commit },
    organisation: OrganisationEntry | OrganisationEntry[]
  ) => {
    let organisationEntry = await OrganisationService.saveOrgansiation(
      organisation
    );
    let i = 0;
    while (organisationEntry == null && i++ < 5) {
      await wait(500 * (i + 1));
      organisationEntry = await OrganisationService.saveOrgansiation(
        organisation
      );
    }

    commit(ADD_OR_UPDATE_ORGANISATION, organisationEntry);
    return organisationEntry;
  },
  [REMOVE_ORGANISATIONS]: async ({
    commit,
    state
  }: {
    commit: Commit;
    state: State;
  }) => {
    const ids = state.organisations.map(o => o?._id as string);
    if (ids.length > 0) {
      await OrganisationService.removeOrganisations(ids);
      commit(REMOVE_ORGANISATIONS);
    }
  }
};

const mutations = {
  [SET_ORGANISATIONS]: (state: State, organisations: OrganisationEntry[]) => {
    state.organisations = organisations;
  },
  [ADD_OR_UPDATE_ORGANISATION]: (
    state: State,
    _organisation: OrganisationEntry
  ) => {
    const organisations = Array.isArray(_organisation)
      ? (_organisation as OrganisationEntry[])
      : undefined;
    const organisation = !organisations
      ? (_organisation as OrganisationEntry)
      : undefined;

    const addOrUpdate = (_org: OrganisationEntry) => {
      const idx = state.organisations.findIndex(o => o._id === _org._id);
      if (idx > -1) {
        state.organisations[idx] = _org;
      } else {
        state.organisations.push(_org);
      }
    };

    if (organisations) {
      organisations.forEach(o => addOrUpdate(o));
    } else if (organisation) {
      addOrUpdate(organisation);
    }
  },
  [REMOVE_ORGANISATIONS]: (state: State) => {
    state.organisations = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
