import { Commit } from "vuex";

import { LoginData } from '@/models/auth.model';

import AuthService from '@/services/auth.service';
import {
  GET_LOGGED_IN,
  GET_TOKEN,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT
} from './auth.actions';

interface State {
  token: string | null;
  loggedIn: boolean;
}

const token = localStorage.getItem('token');
const state: State = {
  token: token,
  loggedIn: !!token
};

const getters = {
  [GET_TOKEN](state: State) {
    return state.token;
  },
  [GET_LOGGED_IN](state: State) {
    return state.loggedIn;
  }
};

const actions = {
  [LOGIN]: async ({ commit }: { commit: Commit }, loginData: LoginData) => {
    try {
      const { apiKey, status } = await AuthService.login(loginData);
      if (status === 200) {
        commit(LOGIN_SUCCESS, apiKey);
      } else {
        commit(LOGIN_FAILURE);
      }

      return status;
    } catch (error) {
      commit(LOGIN_FAILURE);
    }
  },
  [LOGOUT]: ({ commit }: { commit: Commit }) => {
    AuthService.logout();
    commit(LOGOUT);
  }
};

const mutations = {
  [LOGIN_SUCCESS]: (state: State, token: string) => {
    state.token = token;
    state.loggedIn = true;
  },
  [LOGIN_FAILURE]: (state: State) => {
    state.token = null;
    state.loggedIn = false;
  },
  [LOGOUT]: (state: State) => {
    state.token = null;
    state.loggedIn = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
