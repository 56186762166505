// mutations
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const ADD_OR_UPDATE_LOCATION = 'ADD_OR_UPDATE_LOCATION';

// actions
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const CREATE_OR_SAVE_LOCATION = 'CREATE_OR_SAVE_LOCATION';

// getters
export const GET_LOCATIONS = 'GET_LOCATIONS';
