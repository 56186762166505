import { Commit } from 'vuex';

import { Report, ReportInput } from '@/models/report.model';
import ReportService from '@/services/report.service';

import {
  SET_REPORTS,
  GET_REPORTS,
  CREATE_OR_SAVE_REPORT,
  ADD_OR_UPDATE_REPORT,
  FETCH_REPORTS,
  DELETE_REPORT,
  REMOVE_REPORTS
} from './report.actions';

import { wait } from '@/helpers/wait';
import { parseISO } from 'date-fns';

interface State {
  reports: Report[];
}

const state: State = {
  reports: []
};

const getters = {
  [GET_REPORTS](state: State) {
    return state.reports;
  }
};

const actions = {
  [FETCH_REPORTS]: async ({ commit }: { commit: Commit }) => {
    const reportEntries = await ReportService.getAllReports();
    commit(SET_REPORTS, reportEntries);
    return reportEntries;
  },
  [CREATE_OR_SAVE_REPORT]: async (
    { commit }: { commit: Commit },
    report: ReportInput | ReportInput[]
  ) => {
    let reportEntry = await ReportService.saveReport(report);
    let i = 0;
    while (reportEntry == null && i++ < 5) {
      await wait(500 * (i + 1));
      reportEntry = await ReportService.saveReport(report);
    }

    commit(ADD_OR_UPDATE_REPORT, reportEntry);
    return reportEntry;
  },
  [DELETE_REPORT]: async ({ commit }: { commit: Commit }, id: string) => {
    await ReportService.deleteReport(id);
    commit(DELETE_REPORT, id);
  },
  [REMOVE_REPORTS]: async ({
    commit,
    state
  }: {
    commit: Commit;
    state: State;
  }) => {
    const ids = state.reports.map(rep => rep?._id as string);
    if (ids.length > 0) {
      await ReportService.removeReports(ids);
      commit(REMOVE_REPORTS);
    }
  }
};

const mutations = {
  [SET_REPORTS]: (state: State, reports: Report[]) => {
    state.reports = reports;
  },
  [ADD_OR_UPDATE_REPORT]: (state: State, op: Report | Report[]) => {
    const reports = Array.isArray(op) ? (op as Report[]) : undefined;
    const report = !reports ? (op as Report) : undefined;

    const addOrUpdate = (_op: Report) => {
      const idx = state.reports.findIndex(op => op._id === _op._id);
      if (idx > -1) {
        state.reports[idx] = _op;
      } else {
        state.reports.unshift(_op);
      }

      state.reports.sort(
        (lhs, rhs) =>
          parseISO(rhs.zeitstempel).getTime() -
          parseISO(lhs.zeitstempel).getTime()
      );
    };

    if (reports) {
      reports.forEach(_op => addOrUpdate(_op));
    } else if (report) {
      addOrUpdate(report);
    }
  },
  [DELETE_REPORT]: (state: State, id: string) => {
    state.reports = state.reports.filter(op => op._id !== id);
  },
  [REMOVE_REPORTS]: (state: State) => {
    state.reports = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
