import { AxiosResponse } from 'axios';

import axiosClient from '@/plugins/axios.client';
import $emitter from '@/plugins/mitt.event';

import { OperationInput, RemoveAsset } from '@/models/operation.model';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
// import { startOfYear, subYears } from 'date-fns';

class OperationService {
  getAllOperations() {
    // const today = new Date();
    // const earliestDate = subYears(today, 3);
    // const earliest = startOfYear(earliestDate).toISOString();

    return axiosClient
      .post(`collections/get/einsatz`, {
        populate: 1,
        // filter: {
        //   zeitstempel: {
        //     $gt: earliest
        //   }
        // }
        sort: {
          zeitstempel: -1
        }
      })
      .then((resp: AxiosResponse) => resp.data.entries);
  }

  saveOperation(operation: OperationInput | OperationInput[]) {
    return axiosClient
      .post(`collections/save/einsatz`, {
        data: operation
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Einsatz erfolgreich angelegt.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Einsatz konnte nicht angelegt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einsatz konnte nicht angelegt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }

  deleteOperation(id: string) {
    return axiosClient
      .post('collections/remove/einsatz', {
        filter: {
          _id: id
        }
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Einsatz erfolgreich entfernt.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Einsatz konnte nicht entfernt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einsatz konnte nicht entfernt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }

  getImage(id: string, m?: string, w?: number, h?: number) {
    return axiosClient
      .post('cockpit/image', {
        src: id,
        m: m ?? 'fitToHeight',
        w: w,
        h: h ?? 500
      })
      .then((resp: AxiosResponse) => resp.data);
  }

  getAsset(id: string) {
    return axiosClient
      .post('cockpit/assets', {
        filter: {
          _id: id
        }
      })
      .then((resp: AxiosResponse) => resp.data.assets?.[0]);
  }

  uploadImage(images: File[]) {
    if (!images || images.length === 0) {
      return Promise.resolve([]);
    }

    const imagesFormData = new FormData();
    images.forEach(img => imagesFormData.append('files[]', img));

    return axiosClient
      .post('cockpit/addAssets', imagesFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Bild(er) erfolgreich hochgeladen.';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Bild(er) konnte(n) nicht hochgeladen werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Bild(er) konnte(n) nicht hochgeladen werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }

  removeAssets(removeAssets: RemoveAsset[]) {
    return axiosClient
      .post('cockpit/removeAssets', {
        assets: removeAssets
      })
      .then((resp: AxiosResponse) => {
        const msg: Message = {
          type: MessageType.Default,
          text: ''
        };

        if (resp.status === 200) {
          msg.text = 'Bild(er) erfolgreich entfernt';
          msg.type = MessageType.Success;
        } else {
          msg.text = 'Bild(er) konnte(n) nicht entfernt werden';
          msg.type = MessageType.Error;
        }

        $emitter?.emit(MessageEvent.Global, msg);
        return resp.data;
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Bild(er) konnte(n) nicht entfernt werden'
        };
        $emitter?.emit(MessageEvent.Global, msg);
      });
  }

  removeOperations(ids: string[]) {
    return axiosClient
      .post(`collections/remove/einsatz`, {
        filter: {
          _id: {
            $in: ids
          }
        }
      })
      .then((resp: AxiosResponse) => resp.data);
  }
}

export default new OperationService();
