import { AxiosResponse } from 'axios';

import axiosClient from '@/plugins/axios.client';
import $emitter from '@/plugins/mitt.event';

import { LoginData, User } from '@/models/auth.model';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';

class AuthService {
  login(loginData: LoginData) {
    return axiosClient
      .post(`cockpit/authUser`, {
        user: loginData.user,
        password: loginData.password
      })
      .then((resp: AxiosResponse) => {
        const statusResult = resp.status;
        if (statusResult === 200) {
          const msg: Message = {
            type: MessageType.Success,
            text: 'Login erfolgreich'
          };
          $emitter?.emit(MessageEvent.Global, msg);
        } else {
          const msg: Message = {
            type: MessageType.Error,
            text: 'Login fehlgeschlagen'
          };
          $emitter?.emit(MessageEvent.Global, msg);

          return { apiKey: '', status: statusResult };
        }

        const user = resp.data as User;
        if (user.api_key) {
          localStorage.setItem('token', user.api_key);
        }

        return { apiKey: user.api_key, status: statusResult };
      })
      .catch(() => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Login fehlgeschlagen'
        };
        $emitter?.emit(MessageEvent.Global, msg);

        return { apiKey: '', status: null };
      });
  }

  logout() {
    localStorage.removeItem('token');
  }
}

export default new AuthService();
