import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import operation from './operation.module';
import car from './car.module';
import organisation from './organisation.module';
import location from './location.module';
import operationType from './operation-type.module';
import report from './report.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    operation,
    car,
    organisation,
    location,
    operationType,
    report
  }
});
