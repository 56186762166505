// mutations
export const SET_CARS = 'SET_CARS';
export const ADD_OR_UPDATE_CAR = 'ADD_OR_UPDATE_CAR';
export const REMOVE_CARS = 'REMOVE_CARS';

// actions
export const FETCH_CARS = 'FETCH_CARS';
export const CREATE_OR_SAVE_CAR = 'CREATE_OR_SAVE_CAR';

// getters
export const GET_CARS = 'GET_CARS';
