import { Commit } from 'vuex';

import { CarEntry } from '@/models/car.model';
import CarService from '@/services/car.service';

import {
  SET_CARS,
  GET_CARS,
  FETCH_CARS,
  CREATE_OR_SAVE_CAR,
  ADD_OR_UPDATE_CAR,
  REMOVE_CARS
} from './car.actions';
import { wait } from '@/helpers/wait';
import { sortCars } from '@/helpers/sort-entries';
import Vue from 'vue';

interface State {
  cars: CarEntry[];
}

const state: State = {
  cars: []
};

const getters = {
  [GET_CARS](state: State) {
    return state.cars;
  }
};

const actions = {
  [FETCH_CARS]: async ({ commit }: { commit: Commit }) => {
    const carEntries = await CarService.getAllCars();
    sortCars(carEntries);
    commit(SET_CARS, carEntries);
    return carEntries;
  },
  [CREATE_OR_SAVE_CAR]: async (
    { commit }: { commit: Commit },
    car: CarEntry | CarEntry[]
  ) => {
    let carEntry = await CarService.saveCar(car);
    let i = 0;
    while (carEntry == null && i++ < 5) {
      await wait(500 * (i + 1));
      carEntry = await CarService.saveCar(car);
    }
    commit(ADD_OR_UPDATE_CAR, carEntry);
    return carEntry;
  },
  [REMOVE_CARS]: async ({
    commit,
    state
  }: {
    commit: Commit;
    state: State;
  }) => {
    const ids = state.cars.map(c => c?._id as string);
    if (ids.length > 0) {
      await CarService.removeCars(ids);
      commit(REMOVE_CARS);
    }
  }
};

const mutations = {
  [SET_CARS]: (state: State, cars: CarEntry[]) => {
    state.cars = cars;
  },
  [ADD_OR_UPDATE_CAR]: (state: State, _car: CarEntry | CarEntry[]) => {
    const cars = Array.isArray(_car) ? (_car as CarEntry[]) : undefined;
    const car = !cars ? (_car as CarEntry) : undefined;

    const addOrUpdate = (_car: CarEntry) => {
      const idx = state.cars.findIndex(c => c._id === _car._id);
      if (idx > -1) {
        // state.cars[idx] = _car;
        Vue.set(state.cars, idx, car);
      } else {
        state.cars.push(_car);
      }
    };

    if (cars) {
      cars.forEach(_car => addOrUpdate(_car));
    } else if (car) {
      addOrUpdate(car);
    }
  },
  [REMOVE_CARS]: (state: State) => {
    state.cars = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
