import { Commit } from 'vuex';

import { LocationEntry } from '@/models/location.model';
import LocationService from '@/services/location.service';

import {
  SET_LOCATIONS,
  GET_LOCATIONS,
  FETCH_LOCATIONS,
  CREATE_OR_SAVE_LOCATION,
  ADD_OR_UPDATE_LOCATION
} from './location.actions';
import { wait } from '@/helpers/wait';

interface State {
  locations: LocationEntry[];
}

const state: State = {
  locations: []
};

const getters = {
  [GET_LOCATIONS](state: State) {
    return state.locations;
  }
};

const actions = {
  [FETCH_LOCATIONS]: async ({ commit }: { commit: Commit }) => {
    const locationEntries = await LocationService.getAllLocations();
    commit(SET_LOCATIONS, locationEntries);
    return locationEntries;
  },
  [CREATE_OR_SAVE_LOCATION]: async (
    { commit }: { commit: Commit },
    location: LocationEntry | LocationEntry[]
  ) => {
    let locationEntry = await LocationService.saveLocation(location);
    let i = 0;
    while (locationEntry == null && i++ < 5) {
      await wait(500 * (i + 1));
      locationEntry = await LocationService.saveLocation(location);
    }

    commit(ADD_OR_UPDATE_LOCATION, locationEntry);
    return locationEntry;
  }
};

const mutations = {
  [SET_LOCATIONS]: (state: State, locations: LocationEntry[]) => {
    state.locations = locations;
  },
  [ADD_OR_UPDATE_LOCATION]: (state: State, _location: LocationEntry) => {
    const locations = Array.isArray(_location)
      ? (_location as LocationEntry[])
      : undefined;
    const location = !locations ? (_location as LocationEntry) : undefined;

    const addOrUpdate = (_loc: LocationEntry) => {
      const idx = state.locations.findIndex(o => o._id === _loc._id);
      if (idx > -1) {
        state.locations[idx] = _loc;
      } else {
        state.locations.push(_loc);
      }
    };

    if (locations) {
      locations.forEach(o => addOrUpdate(o));
    } else if (location) {
      addOrUpdate(location);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
