import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import { useActions, useGetters } from 'vuex-composition-helpers';

import $emitter from '@/plugins/mitt.event';

import store from '@/store';
import { GET_LOGGED_IN } from '@/store/auth.actions';
import { FETCH_OPERATIONS, GET_OPERATIONS } from '@/store/operation.actions';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import { LzId } from '@/models/car.model';
import { FETCH_REPORTS, GET_REPORTS } from '@/store/report.actions';

Vue.use(VueRouter);

const ifAuthenticated = (to: Route, from: Route, next: NavigationGuardNext) => {
  const getters = useGetters(store, [GET_LOGGED_IN]);
  const loggedIn = getters[GET_LOGGED_IN];

  if (loggedIn.value) {
    next();
    return;
  }

  // const $emitter = inject('$emitter') as Emitter kann nur in setup() verwendet werden
  const msg: Message = {
    type: MessageType.Error,
    text: 'Login erforderlich'
  };
  $emitter?.emit(MessageEvent.Global, msg);

  next('/login');
};

const getOps = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const getters = useGetters(store, [GET_OPERATIONS]);
  const operations = getters[GET_OPERATIONS];

  if (operations.value?.length > 0) {
    next();
    return;
  }

  const actions = useActions(store, [FETCH_OPERATIONS]);
  const fetchOperationsAction = actions[FETCH_OPERATIONS];

  await fetchOperationsAction();
  next();
};

const getReports = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const getters = useGetters(store, [GET_REPORTS]);
  const reports = getters[GET_REPORTS];

  if (reports.value?.length > 0) {
    next();
    return;
  }

  const actions = useActions(store, [FETCH_REPORTS]);
  const fetchReportsAction = actions[FETCH_REPORTS];

  await fetchReportsAction();
  next();
};

const ifOperationAuthenticated = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const getters = useGetters(store, [GET_LOGGED_IN]);
  const loggedIn = getters[GET_LOGGED_IN];

  if (loggedIn.value) {
    await getOps(to, from, next);
    return;
  }

  // const $emitter = inject('$emitter') as Emitter kann nur in setup() verwendet werden
  const msg: Message = {
    type: MessageType.Error,
    text: 'Login erforderlich'
  };
  $emitter?.emit(MessageEvent.Global, msg);

  next('/login');
};

const ifReportAuthenticated = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const getters = useGetters(store, [GET_LOGGED_IN]);
  const loggedIn = getters[GET_LOGGED_IN];

  if (loggedIn.value) {
    await getReports(to, from, next);
    return;
  }

  // const $emitter = inject('$emitter') as Emitter kann nur in setup() verwendet werden
  const msg: Message = {
    type: MessageType.Error,
    text: 'Login erforderlich'
  };
  $emitter?.emit(MessageEvent.Global, msg);

  next('/login');
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/layouts/home/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/fahrzeuge_everswinkel',
        name: 'FZ_EV',
        component: () => import('@/views/CarsCombinedView.vue'),
        props: { lz: 'ev' as LzId }
      },
      {
        path: '/fahrzeuge_alverskirchen',
        name: 'FZ_AL',
        component: () => import('@/views/CarsCombinedView.vue'),
        props: { lz: 'al' as LzId }
      },
      {
        path: '/fahrzeuge',
        name: 'FZ_KO',
        component: () => import('@/views/CarsCombinedView.vue')
      },
      {
        path: '/fahrzeug/:id',
        name: 'FZ',
        component: () => import('@/views/Car.vue'),
        props: true
      },
      {
        path: '/brandschutzerziehung',
        name: 'BZE',
        component: () => import('@/views/FireProtectionEducation.vue')
      },
      {
        path: '/links',
        name: 'LI',
        component: () => import('@/views/Links.vue')
      },
      {
        path: '/ldf',
        name: 'LF',
        component: () => import('@/views/ProfileLdf.vue')
      },
      {
        path: '/lz-ev',
        name: 'LZ_EV',
        component: () => import('@/views/ProfileEv.vue')
      },
      {
        path: '/lz-alv',
        name: 'LZ_AV',
        component: () => import('@/views/ProfileAv.vue')
      },
      {
        path: '/jf',
        name: 'JF',
        component: () => import('@/views/ProfileJf.vue')
      },
      {
        path: '/jf-ausflug',
        name: 'JF_AF',
        component: () => import('@/views/ExcursionJf.vue')
      },
      {
        path: '/jf-elterninfo',
        name: 'JF_EI',
        component: () => import('@/views/ParentInfoJf.vue')
      },
      {
        path: '/ea',
        name: 'EA',
        component: () => import('@/views/ProfileEa.vue')
      },
      {
        path: '/einsatz-eingabe/:id?',
        name: 'OI',
        component: () => import('@/views/OperationForm.vue'),
        beforeEnter: ifOperationAuthenticated,
        props: true
      },
      {
        path: '/einsatz-liste',
        name: 'OL',
        component: () => import('@/views/OperationListView.vue')
      },
      {
        path: '/einsatz/:id',
        name: 'OD',
        component: () => import('@/views/OperationDetail.vue'),
        beforeEnter: getOps,
        props: true
      },
      {
        path: '/login',
        name: 'LO',
        component: () => import('@/views/Login.vue')
      },
      {
        path: '/backup',
        name: 'BW',
        component: () => import('@/views/UploadBackup.vue')
      },
      {
        path: '/impressum',
        name: 'IM',
        component: () => import('@/views/Imprint.vue')
      },
      {
        path: '/datenschutz',
        name: 'PR',
        component: () => import('@/views/Privacy.vue')
      },
      {
        path: '/teo/start',
        name: 'TEO_EI',
        component: () => import('@/views/ProfileTeo.vue')
      },
      {
        path: '/teo/wasserkomponente',
        name: 'TEO_WK',
        component: () => import('@/views/ProfileTeoWasserkomponente.vue')
      },
      {
        path: '/teo/notstromkomponente',
        name: 'TEO_NK',
        component: () => import('@/views/ProfileTeoNotstromkomponente.vue')
      },
      {
        path: '/teo/kleiderkammer',
        name: 'TEO_KK',
        component: () => import('@/views/ProfileTeoKleiderkammer.vue')
      },
      {
        path: '/teo/fahrzeugtechnik',
        name: 'TEO_FT',
        component: () => import('@/views/ProfileTeoFahrzeugtechnik.vue')
      },
      {
        path: '/teo/ausbildung',
        name: 'TEO_AU',
        component: () => import('@/views/ProfileTeoAusbildung.vue')
      },
      {
        path: '/teo/geraetewartung',
        name: 'TEO_GW',
        component: () => import('@/views/ProfileTeo.vue')
        // component: () => import('@/views/ProfileTeoWasserKomponente.vue')
      },
      {
        path: '/geschichte/anfaenge',
        name: 'HSE',
        component: () => import('@/views/HistoryStartEv.vue')
      },
      {
        path: '/geschichte/einleitung',
        name: 'HSI',
        component: () => import('@/views/HistoryIntroEv.vue')
      },
      {
        path: '/geschichte/zeitstrahl',
        name: 'HST',
        component: () => import('@/views/HistoryTimelineEv.vue')
      },
      {
        path: '/geschichte/fahrzeuge',
        name: 'HSF',
        component: () => import('@/views/HistoryCars.vue')
      },
      {
        path: '/berichte',
        name: 'BL',
        component: () => import('@/views/ReportListView.vue'),
        beforeEnter: getReports
      },
      {
        path: '/berichte/archiv',
        name: 'BA',
        component: () => import('@/views/ArchiveReportListView.vue'),
        beforeEnter: getReports
      },
      {
        path: '/bericht-eingabe/:id?',
        name: 'RI',
        component: () => import('@/views/ReportForm.vue'),
        beforeEnter: ifReportAuthenticated,
        props: true
      },
      {
        path: '/bericht/:id',
        name: 'RD',
        component: () => import('@/views/ReportDetail.vue'),
        beforeEnter: getReports,
        props: true
      },
      {
        path: '/fahrzeuge-eingabe',
        name: 'FI',
        component: () => import('@/views/CarForm.vue'),
        beforeEnter: ifAuthenticated,
        props: true
      },
      {
        path: '/veranstaltung/:name/f/:file',
        name: 'VE',
        component: () => import('@/views/EventView.vue'),
        props: true
      },
      {
        path: '*',
        name: 'NF',
        component: () => import('@/views/Home.vue')
      }
    ]
  }
  // ,{
  //   path: '/teo',
  //   component: () => import('@/layouts/teo/Index.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'TeoHome',
  //       component: () => import('@/views/TeoHome.vue')
  //     }
  //   ]
  // }
];

const router = new VueRouter({
  routes
});

export default router;
