import { Commit } from 'vuex';

import { Asset, Operation, OperationInput, RemoveAsset } from '@/models/operation.model';
import OperationService from '@/services/operation.service';

import {
  SET_OPERATIONS,
  GET_OPERATIONS,
  CREATE_OR_SAVE_OPERATION,
  ADD_OR_UPDATE_OPERATION,
  FETCH_OPERATIONS,
  FETCH_IMAGE,
  ADD_IMAGE,
  GET_IMAGE,
  UPLOAD_IMAGE,
  SET_UPLOAD_IMAGES,
  GET_UPLOAD_IMAGES,
  DELETE_OPERATION,
  REMOVE_IMAGE,
  FETCH_ASSET,
  ADD_ASSET,
  GET_ASSET,
  REMOVE_OPERATIONS,
  REMOVE_IMAGES
} from './operation.actions';

import { wait } from '@/helpers/wait';
import { CarEntry } from '@/models/car.model';
import { OrganisationEntry } from '@/models/organisation.model';
import { parseISO } from 'date-fns';

interface State {
  operations: Operation[];
  images: { id: string; img: string }[]; // TODO: use dict, thumbnail images
  assets: { [id: string]: Asset };
  uploadImageIds: string[]; // asset ids
}

const state: State = {
  operations: [],
  images: [],
  assets: {},
  uploadImageIds: []
};

const getters = {
  [GET_OPERATIONS](state: State) {
    return state.operations;
  },
  [GET_IMAGE](state: State) {
    return (id: string) => state.images.find(img => img.id === id);
  },
  [GET_ASSET](state: State) {
    return (id: string) => state.assets[id];
  },
  [GET_UPLOAD_IMAGES](state: State) {
    return state.uploadImageIds;
  }
};

const addIndexToOperations = (operations: Operation[]) => {
  let idx = 0;
  for (let i = operations.length - 1; i >= 0; --i) {
    const currOp = operations[i];
    if (i < operations.length - 1) {
      const lastMonth = parseISO(operations[i + 1].zeitstempel).getFullYear();
      const currMonth = parseISO(currOp.zeitstempel).getFullYear();

      if (currMonth > lastMonth) {
        idx = 0;
      }
    }
    currOp.idx = ++idx;
  }
};

const actions = {
  [FETCH_OPERATIONS]: async ({ commit }: { commit: Commit }) => {
    const operationEntries = await OperationService.getAllOperations();
    addIndexToOperations(operationEntries);
    commit(SET_OPERATIONS, operationEntries);
    return operationEntries;
  },
  [CREATE_OR_SAVE_OPERATION]: async (
    { commit, rootState }: { commit: Commit; rootState: any },
    op: OperationInput | OperationInput[]
  ) => {
    let operation = await OperationService.saveOperation(op);
    let i = 0;
    while (operation == null && i++ < 5) {
      await wait(500 * (i + 1));
      operation = await OperationService.saveOperation(op);
    }

    const addCarNames = (_op: Operation) => {
      if (_op == null || !_op.fahrzeuge || (_op.fahrzeuge as any) === '') {
        return;
      }
      _op.fahrzeuge.forEach(f => {
        f.name = rootState.car.cars.find(
          (c: CarEntry) => c._id === f._id
        )?.name;
      });
    };

    const addOrgaNames = (_op: Operation) => {
      if (_op == null || !_op.einheiten || (_op.einheiten as any) === '') {
        return;
      }
      _op.einheiten.forEach(e => {
        e.name = rootState.organisation.organisations.find(
          (o: OrganisationEntry) => o._id === e._id
        )?.name;
      });
    };

    if (Array.isArray(operation)) {
      operation.forEach(_op => {
        addCarNames(_op);
        addOrgaNames(_op);
      });
    } else {
      addCarNames(operation);
      addOrgaNames(operation);
    }

    commit(ADD_OR_UPDATE_OPERATION, operation);
    addIndexToOperations(rootState.operation.operations);
    return operation;
  },
  [DELETE_OPERATION]: async ({ commit }: { commit: Commit }, id: string) => {
    await OperationService.deleteOperation(id);
    commit(DELETE_OPERATION, id);
  },
  [FETCH_IMAGE]: async (
    { commit }: { commit: Commit },
    id: string,
    m?: string,
    w?: number,
    h?: number
  ) => {
    const img = await OperationService.getImage(id, m, w, h);
    if (img) {
      commit(ADD_IMAGE, { id: id, img: img });
    }
    return img;
  },
  [FETCH_ASSET]: async ({ commit }: { commit: Commit }, id: string) => {
    const img = await OperationService.getAsset(id);
    if (img) {
      commit(ADD_ASSET, img);
    }
    return img;
  },
  [UPLOAD_IMAGE]: async ({ commit }: { commit: Commit }, images: File[]) => {
    const windowSize = 2;
    const allImageIds = [];
    for (let i = 0; i < images.length; i += windowSize) {
      // sliding window
      const imagesWindow = images.slice(i, i + windowSize);
      let resp = await OperationService.uploadImage(imagesWindow);
      let j = 0;
      while (resp == null && j++ < 5) {
        await wait(500 * (j + 1));
        resp = await OperationService.uploadImage(imagesWindow);
      }
      const allImages = resp?.assets;
      const imageIds = allImages?.map((img: { _id: string }) => img._id);
      allImageIds.push(...imageIds);
      await wait(100);
    }
    commit(SET_UPLOAD_IMAGES, allImageIds);
    return allImageIds;
  },
  [REMOVE_IMAGE]: async (
    { commit }: { commit: Commit },
    removeImages: RemoveAsset[]
  ) => {
    const resp = await OperationService.removeAssets(removeImages);
    const removedImages = resp;
    commit(REMOVE_IMAGE, removedImages);
    return removedImages;
  },
  [REMOVE_OPERATIONS]: async ({
    commit,
    state
  }: {
    commit: Commit;
    state: State;
  }) => {
    const removeAssets: RemoveAsset[] = [];
    state.operations?.forEach(op =>
      op.bilder?.forEach(img => {
        removeAssets.push({ _id: img });
      })
    );
    if (removeAssets.length > 0) {
      await OperationService.removeAssets(removeAssets);
      commit(REMOVE_IMAGES);
    }

    const ids = state.operations.map(op => op?._id as string);
    if (ids.length > 0) {
      await OperationService.removeOperations(ids);
      commit(REMOVE_OPERATIONS);
    }
  }
};

const mutations = {
  [SET_OPERATIONS]: (state: State, operations: Operation[]) => {
    state.operations = operations;
  },
  [ADD_OR_UPDATE_OPERATION]: (state: State, op: Operation | Operation[]) => {
    const operations = Array.isArray(op) ? (op as Operation[]) : undefined;
    const operation = !operations ? (op as Operation) : undefined;

    const addOrUpdate = (_op: Operation) => {
      const idx = state.operations.findIndex(op => op._id === _op._id);
      if (idx > -1) {
        state.operations[idx] = _op;
      } else {
        state.operations.unshift(_op);
      }

      state.operations.sort(
        (lhs, rhs) =>
          parseISO(rhs.zeitstempel).getTime() -
          parseISO(lhs.zeitstempel).getTime()
      );
    };

    if (operations) {
      operations.forEach(_op => addOrUpdate(_op));
    } else if (operation) {
      addOrUpdate(operation);
    }
  },
  [DELETE_OPERATION]: (state: State, id: string) => {
    state.operations = state.operations.filter(op => op._id !== id);
  },
  [ADD_IMAGE]: (state: State, payload: { id: string; img: string }) => {
    state.images.push(payload);
  },
  [ADD_ASSET]: (state: State, payload: Asset) => {
    state.assets[payload._id] = payload;
  },
  [REMOVE_IMAGE]: (state: State, payload: RemoveAsset[]) => {
    state.images = state.images.filter(
      img => !payload.some(removeAsset => removeAsset._id === img.id)
    );
  },
  [SET_UPLOAD_IMAGES]: (state: State, payload: string[]) => {
    state.uploadImageIds = payload;
  },
  [REMOVE_OPERATIONS]: (state: State) => {
    state.operations = [];
  },
  [REMOVE_IMAGES]: (state: State) => {
    state.assets = {};
    state.uploadImageIds = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
