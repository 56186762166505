// mutations
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// actions
export const LOGIN = 'LOGIN';

// getters
export const GET_TOKEN = 'GET_TOKEN';
export const GET_LOGGED_IN = 'GET_LOGGED_IN';
