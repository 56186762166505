// mutations
export const SET_OPERATION_TYPES = 'SET_OPERATION_TYPES';
export const ADD_OR_UPDATE_OPERATION_TYPE = 'ADD_OR_UPDATE_OPERATION_TYPE';

// actions
export const FETCH_OPERATION_TYPES = 'FETCH_OPERATION_TYPES';
export const CREATE_OR_SAVE_OPERATION_TYPE = 'CREATE_OR_SAVE_OPERATION_TYPE';

// getters
export const GET_OPERATION_TYPES = 'GET_OPERATION_TYPES';
