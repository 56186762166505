import { Commit } from 'vuex';

import { OperationTypeEntry } from '@/models/operation-type.model';
import OperationTypeService from '@/services/operation-type.service';

import {
  SET_OPERATION_TYPES,
  GET_OPERATION_TYPES,
  FETCH_OPERATION_TYPES,
  CREATE_OR_SAVE_OPERATION_TYPE,
  ADD_OR_UPDATE_OPERATION_TYPE
} from './operation-type.actions';
import { wait } from '@/helpers/wait';

interface State {
  operationTypes: OperationTypeEntry[];
}

const state: State = {
  operationTypes: []
};

const getters = {
  [GET_OPERATION_TYPES](state: State) {
    return state.operationTypes;
  }
};

const actions = {
  [FETCH_OPERATION_TYPES]: async ({ commit }: { commit: Commit }) => {
    const operationTypeEntries = await OperationTypeService.getAllOperationTypes();
    commit(SET_OPERATION_TYPES, operationTypeEntries);
    return operationTypeEntries;
  },
  [CREATE_OR_SAVE_OPERATION_TYPE]: async (
    { commit }: { commit: Commit },
    operationType: OperationTypeEntry | OperationTypeEntry[]
  ) => {
    let operationTypeEntry = await OperationTypeService.saveOperationType(
      operationType
    );
    let i = 0;
    while (operationTypeEntry == null && i++ < 5) {
      await wait(500 * (i + 1));
      operationTypeEntry = await OperationTypeService.saveOperationType(
        operationType
      );
    }

    commit(ADD_OR_UPDATE_OPERATION_TYPE, operationTypeEntry);
    return operationTypeEntry;
  }
};

const mutations = {
  [SET_OPERATION_TYPES]: (
    state: State,
    operationTypes: OperationTypeEntry[]
  ) => {
    state.operationTypes = operationTypes;
  },
  [ADD_OR_UPDATE_OPERATION_TYPE]: (
    state: State,
    _operationType: OperationTypeEntry
  ) => {
    const operationTypes = Array.isArray(_operationType)
      ? (_operationType as OperationTypeEntry[])
      : undefined;
    const operationType = !operationTypes
      ? (_operationType as OperationTypeEntry)
      : undefined;

    const addOrUpdate = (_opType: OperationTypeEntry) => {
      const idx = state.operationTypes.findIndex(o => o._id === _opType._id);
      if (idx > -1) {
        state.operationTypes[idx] = _opType;
      } else {
        state.operationTypes.push(_opType);
      }
    };

    if (operationTypes) {
      operationTypes.forEach(o => addOrUpdate(o));
    } else if (operationType) {
      addOrUpdate(operationType);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
